<template>
  <Spinner v-if="isLoading"/>

  <div v-else>
    <VButtonPageBack
      class="mb-30"
      to-page-name="Users"
    />

    <div class="between ai-c mb-30">
      <h1>{{ pageTitle }}</h1>

      <VButton
        v-if="isDetail"
        text="Редактировать"
        icon="edit"
        size="sm"
        @click.native="goEdit"
      />
    </div>

    <VForm
      v-model="formData"
      @request="userSaveHandler"
      ref="refUser"
      class="create__user"
    >
        <div class="create__user-form" :class="{ 'mb-20' : !(isNewPassword || isCreate) }">
          <VInput
            label="Фамилия"
            name="surname"
            :disabled="isDisabled"
          />

          <VInput
            label="Имя"
            name="name"
            :disabled="isDisabled"
            required
          >
            <Validation for="required"/>
          </VInput>

          <VInput
            label="Отчество"
            name="patronymic"
            :disabled="isDisabled"
          />

          <VInput
            label="Электронная почта"
            name="email"
            email
            :disabled="isDisabled"
          />

          <VInput
            label="Номер телефона"
            name="phone"
            maskRegex="+7 (###) ### ##-##"
            :unmaskOnInput="true"
            :disabled="isDisabled"
          />

          <VSelect
            label="Должность"
            name="post"
            :options="userPosts"
            :disabled="isDisabled"
          />

          <VSelect
            label="Отдел"
            name="department"
            :options="userDepartments"
            :disabled="isDisabled"
          />

          <VInput
            name="username"
            label="Логин"
            :disabled="isDisabled"
            required
          >
            <Validation for="required"/>
          </VInput>

          <VSelect
            label="Роль"
            name="role"
            item-text="name_translate"
            :options="userRoles"
            :disabled="isDisabled"
            required
          >
            <Validation for="required"/>
          </VSelect>
        </div>

        <div
          v-if="isNewPassword || isCreate"
          class="create__user-form mb-20"
        >
          <VInput
            label="Пароль"
            name="password"
            type="password"
            :disabled="isDisabled"
            required
            pattern="^(?=^.{6,}$)(?=.*\d)(?=.*[a-z])(?!.*\s).*$"
          >
            <Validation for="required"/>
            <Validation :absolute="false" for="pattern">
              Пароль должен состоять из 6 или более символов латинского алфавита, содержать буквы и цифры.
            </Validation>
          </VInput>

          <VInput
            label="Повторите пароль"
            type="password"
            name="password_confirmation"
            :disabled="isDisabled"
            required
            :sameAs="user.newPassword"
          >
            <Validation for="required"/>
            <Validation :absolute="false" for="sameAs">
              Пароли не совпадают
            </Validation>
          </VInput>
        </div>

        <div v-if="!isDetail" class="buttons">
          <VButton
            :text="isEdit ? 'Сохранить' : 'Создать'"
            type="submit"
            color="primary"
            size="xl"
            :loading="$store.state.isSendingRequestPage"
          />

          <VButton
            text="Отменить"
            size="xl"
            @click.native="onCancel"
          />

          <VButton
            v-if="isEdit"
            text="Изменить пароль"
            size="xl"
            @click.native="isNewPassword = !isNewPassword"
          />
        </div>
    </VForm>
  </div>
</template>

<script>
import VForm from '@/components/Form/VForm'
import VInput from '@/components/Form/Vinput/VInput'
import VSelect from '@/components/Form/VSelect'
import Validation from '@/components/Form/Validation'
import VButton from '@/components/simple/button/VButton'
import VButtonPageBack from '@/components/simple/button/VButtonPageBack'

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { goToPage } from '@/utils/router'

export default {
  components: {
    VForm,
    Validation,
    VButton,
    VButtonPageBack,
    VInput,
    VSelect
  },

  props: {
    action: {
      type: String,
      validator (value) {
        return ['detail', 'create', 'update'].includes(value)
      }
    }
  },

  data () {
    return {
      isDetail: false,
      isCreate: false,
      isEdit: false,

      id: null,
      isLoadingBtn: false,

      formData: {},
      user: {
        newPassword: ''
      },
      isNewPassword: false,
      disabled: false
    }
  },

  computed: {
    ...mapState('users', {
      item: 'item',
      isLoading: 'isLoading'
    }),
    ...mapState('handbook/userRole', {
      userRoles: 'userRoles'
    }),
    ...mapGetters('handbook', {
      userDepartments: 'userDepartment/getList',
      userPosts: 'userPost/getList'
    }),
    isDisabled () {
      return this.disabled || this.isDetail
    },

    pageTitle () {
      return this.isCreate ? 'Создать пользователя'
        : this.isEdit ? 'Редактировать пользователя'
          : 'Информация о пользователе'
    }
  },

  watch: {
    action (value) {
      this.changeAction(value)
    },
    '$route.params.id' (id) {
      this.id = id
    }
  },

  async mounted () {
    this.changeAction(this.action)
    this.id = parseInt(this.$route.params.id)

    if (this.id) {
      await this.loadItem(this.id)
    }

    if (this.isDetail || this.isEdit) {
      this.syncData(this.item)
    }
  },

  beforeDestroy () {
    this.clearFormData()
    this.id = null
    this.isCreate = false
    this.isDetail = false
    this.isEdit = false
  },

  methods: {
    ...mapMutations('users', {
      SET_ITEM: 'SET_ITEM'
    }),
    ...mapActions('users', {
      loadItem: 'loadItem',
      createItem: 'createItem',
      updateItem: 'updateItem',
      syncAll: 'syncAll'
    }),
    ...mapActions({
      setAlertSuccess: 'alert/setAlertSuccess'
    }),

    async userSaveHandler (data) {
      try {
        this.$store.commit('SET_IS_SENDING_REQUEST_PAGE')

        data = {
          ...data,
          department_id: data.department?.id,
          post_id: data.post?.id,
          role_ids: [data.role?.id]
        }

        const res = this.isCreate ?
          await this.createItem({ data }) : await this.updateItem({ id: this.id, data })
        if (res?.status !== 200 && res?.status !== 201) return

        this.syncAll({
          data: res.data,
          isCreate: this.isCreate
        })
        this.setAlertSuccess('Данные успешно сохранены')

        if (this.isCreate) {
          this.SET_ITEM(res.data)
          this.syncData(res.data)
        }

        await this.goToDetail(res.data.id)
      } finally {
        this.$store.commit('SET_IS_SENDING_REQUEST_PAGE', false)
      }
    },

    changeAction (action) {
      this.isCreate = false
      this.isDetail = false
      this.isEdit = false
      switch (action) {
        case 'detail':
          this.isDetail = true
          break
        case 'create':
          this.isCreate = true
          break
        case 'update':
          this.isEdit = true
          break
      }
    },

    goEdit () {
      this.$router.replace({ name: 'UpdateUser', params: { id: this.id } })
    },

    async goToDetail (id) {
      await goToPage('DetailUser', { id, action: 'detail' })
    },

    syncData (data) {
      const role = data.roles ? data.roles[0] : null
      this.formData = Object.assign({}, { ...data, role })
    },
    clearFormData () {
      if (this.$refs.refUser) {
        this.$refs.refUser.clear()
      }
    },

    onCancel () {
      if (this.isCreate) {
        return goToPage('Users')
      }

      this.isNewPassword = false
      this.syncData(this.item)
      return this.goToDetail(this.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.create__user {
  flex-direction: column;
  gap: 15px;

  .create__user-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 33px;
    row-gap: 12px;
    border-radius: 4px;
    width: 100%;
  }
}
</style>
